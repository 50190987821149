.overlay_modal_regulamento {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: rgba(0, 70, 0, 1);
}

.new_modal_regulamento {
    position: relative;
    background-color: white;
    width: 90%;
    height: 90%;
    border-radius: 10px 10px 0 0;
    padding: 40px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.text_container {
    padding: 0 30px;
    color: rgb(97, 97, 97);
    max-height: 90%;
    overflow-y: scroll;
}

.text_container h1 {
    font-size: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    top: 5px;

}

.text_container h2 {
    font-size: 1.2rem;
    padding-bottom: 10px;
    text-align: center;
}

.text_item + .text_item {
    margin-top: 20px;
}

.buttons {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

button + button {
    margin-top: 10px;
}

.button_confirmar {
    border: none;
    border-radius: 25px;
    background-color: green;
    color: #fff;
    font-weight: 700;
    font-style: italic;
    width: 100%;
    height: 35px;
    cursor: pointer;
    transition: .2s;
}

.button_confirmar:hover {
    filter: brightness(0.8);
}

.button_cancelar {
    background-color: rgb(240, 240, 240);
    border: none;
    border-radius: 25px;
    color: #888;
    font-weight: 700;
    font-style: italic;
    width: 100%;
    height: 35px;
    cursor: pointer;
    transition: .2s;
}

.button_cancelar:hover {
    filter: brightness(0.8);
}

@media(min-width:770px) {
    .new_modal_regulamento {
        max-width: 1000px;
    }
}